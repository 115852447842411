html {
  font-size: 62.5% !important;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.4 !important;
  font-family: 'Poppins', sans-serif !important;
}

input {
  outline-style:none;
  box-shadow:none;
  border-color:transparent;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*::-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

*::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.15);
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 135, 139, 0.8);
}

/* Inverted UI */

*.ui.inverted::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

*.ui.inverted::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.25);
}

*.ui.inverted::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.15);
}

*.ui.inverted::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.35);
}